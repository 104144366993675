.flag {
  background-image: url('../imgs/flags.png');
  background-repeat: no-repeat;
  display: inline-block;
  width: 35px;
  height: 24px;
  background-position: 100px 100px;
}

.flag-small {
  transform:scale(0.68);
  float: left;
  background-position: -95px -515px;
}

.flag-ad {
  width: 35px;
  height: 24px;
  background-position: -5px -5px;
}

.flag-ae {
  width: 35px;
  height: 24px;
  background-position: -50px -5px;
}

.flag-af {
  width: 35px;
  height: 24px;
  background-position: -95px -5px;
}

.flag-ag {
  width: 35px;
  height: 24px;
  background-position: -140px -5px;
}

.flag-al {
  width: 35px;
  height: 24px;
  background-position: -185px -5px;
}

.flag-am {
  width: 35px;
  height: 24px;
  background-position: -230px -5px;
}

.flag-ar {
  width: 35px;
  height: 24px;
  background-position: -275px -5px;
}

.flag-at {
  width: 35px;
  height: 24px;
  background-position: -320px -5px;
}

.flag-au {
  width: 35px;
  height: 24px;
  background-position: -365px -5px;
}

.flag-az {
  width: 35px;
  height: 24px;
  background-position: -410px -5px;
}

.flag-ba {
  width: 35px;
  height: 24px;
  background-position: -455px -5px;
}

.flag-bb {
  width: 35px;
  height: 24px;
  background-position: -500px -5px;
}

.flag-bd {
  width: 35px;
  height: 24px;
  background-position: -5px -39px;
}

.flag-be {
  width: 35px;
  height: 24px;
  background-position: -50px -39px;
}

.flag-bf {
  width: 35px;
  height: 24px;
  background-position: -95px -39px;
}

.flag-bg {
  width: 35px;
  height: 24px;
  background-position: -140px -39px;
}

.flag-bh {
  width: 35px;
  height: 24px;
  background-position: -185px -39px;
}

.flag-bi {
  width: 35px;
  height: 24px;
  background-position: -230px -39px;
}

.flag-bj {
  width: 35px;
  height: 24px;
  background-position: -275px -39px;
}

.flag-bn {
  width: 35px;
  height: 24px;
  background-position: -320px -39px;
}

.flag-bo {
  width: 35px;
  height: 24px;
  background-position: -365px -39px;
}

.flag-br {
  width: 35px;
  height: 24px;
  background-position: -410px -39px;
}

.flag-bs {
  width: 35px;
  height: 24px;
  background-position: -455px -39px;
}

.flag-bt {
  width: 35px;
  height: 24px;
  background-position: -500px -39px;
}

.flag-bw {
  width: 35px;
  height: 24px;
  background-position: -5px -73px;
}

.flag-by {
  width: 35px;
  height: 24px;
  background-position: -50px -73px;
}

.flag-bz {
  width: 35px;
  height: 24px;
  background-position: -95px -73px;
}

.flag-ca {
  width: 35px;
  height: 24px;
  background-position: -140px -73px;
}

.flag-cd {
  width: 35px;
  height: 24px;
  background-position: -185px -73px;
}

.flag-cf {
  width: 35px;
  height: 24px;
  background-position: -230px -73px;
}

.flag-cg {
  width: 35px;
  height: 24px;
  background-position: -275px -73px;
}

.flag-ch {
  width: 35px;
  height: 24px;
  background-position: -320px -73px;
}

.flag-ci {
  width: 35px;
  height: 24px;
  background-position: -365px -73px;
}

.flag-cl {
  width: 35px;
  height: 24px;
  background-position: -410px -73px;
}

.flag-cm {
  width: 35px;
  height: 24px;
  background-position: -455px -73px;
}

.flag-cn {
  width: 35px;
  height: 24px;
  background-position: -500px -73px;
}

.flag-co {
  width: 35px;
  height: 24px;
  background-position: -5px -107px;
}

.flag-cr {
  width: 35px;
  height: 24px;
  background-position: -50px -107px;
}

.flag-cu {
  width: 35px;
  height: 24px;
  background-position: -95px -107px;
}

.flag-cv {
  width: 35px;
  height: 24px;
  background-position: -140px -107px;
}

.flag-cy {
  width: 35px;
  height: 24px;
  background-position: -185px -107px;
}

.flag-cz {
  width: 35px;
  height: 24px;
  background-position: -230px -107px;
}

.flag-de {
  width: 35px;
  height: 24px;
  background-position: -275px -107px;
}

.flag-dj {
  width: 35px;
  height: 24px;
  background-position: -320px -107px;
}

.flag-dk {
  width: 35px;
  height: 24px;
  background-position: -365px -107px;
}

.flag-dm {
  width: 35px;
  height: 24px;
  background-position: -410px -107px;
}

.flag-do {
  width: 35px;
  height: 24px;
  background-position: -455px -107px;
}

.flag-dz {
  width: 35px;
  height: 24px;
  background-position: -500px -107px;
}

.flag-ec {
  width: 35px;
  height: 24px;
  background-position: -5px -141px;
}

.flag-ee {
  width: 35px;
  height: 24px;
  background-position: -50px -141px;
}

.flag-eg {
  width: 35px;
  height: 24px;
  background-position: -95px -141px;
}

.flag-er {
  width: 35px;
  height: 24px;
  background-position: -140px -141px;
}

.flag-es {
  width: 35px;
  height: 24px;
  background-position: -185px -141px;
}

.flag-et {
  width: 35px;
  height: 24px;
  background-position: -230px -141px;
}

.flag-fi {
  width: 35px;
  height: 24px;
  background-position: -275px -141px;
}

.flag-fj {
  width: 35px;
  height: 24px;
  background-position: -320px -141px;
}

.flag-fm {
  width: 35px;
  height: 24px;
  background-position: -365px -141px;
}

.flag-fr {
  width: 35px;
  height: 24px;
  background-position: -410px -141px;
}

.flag-ga {
  width: 35px;
  height: 24px;
  background-position: -455px -141px;
}

.flag-gb {
  width: 35px;
  height: 24px;
  background-position: -500px -141px;
}

.flag-gd {
  width: 35px;
  height: 24px;
  background-position: -5px -175px;
}

.flag-ge {
  width: 35px;
  height: 24px;
  background-position: -50px -175px;
}

.flag-gh {
  width: 35px;
  height: 24px;
  background-position: -95px -175px;
}

.flag-gm {
  width: 35px;
  height: 24px;
  background-position: -140px -175px;
}

.flag-gn {
  width: 35px;
  height: 24px;
  background-position: -185px -175px;
}

.flag-gq {
  width: 35px;
  height: 24px;
  background-position: -230px -175px;
}

.flag-gr {
  width: 35px;
  height: 24px;
  background-position: -275px -175px;
}

.flag-gt {
  width: 35px;
  height: 24px;
  background-position: -320px -175px;
}

.flag-gw {
  width: 35px;
  height: 24px;
  background-position: -365px -175px;
}

.flag-gy {
  width: 35px;
  height: 24px;
  background-position: -410px -175px;
}

.flag-hn {
  width: 35px;
  height: 24px;
  background-position: -455px -175px;
}

.flag-hr {
  width: 35px;
  height: 24px;
  background-position: -500px -175px;
}

.flag-ht {
  width: 35px;
  height: 24px;
  background-position: -5px -209px;
}

.flag-hu {
  width: 35px;
  height: 24px;
  background-position: -50px -209px;
}

.flag-id {
  width: 35px;
  height: 24px;
  background-position: -95px -209px;
}

.flag-ie {
  width: 35px;
  height: 24px;
  background-position: -140px -209px;
}

.flag-il {
  width: 35px;
  height: 24px;
  background-position: -185px -209px;
}

.flag-in {
  width: 35px;
  height: 24px;
  background-position: -230px -209px;
}

.flag-iq {
  width: 35px;
  height: 24px;
  background-position: -275px -209px;
}

.flag-ir {
  width: 35px;
  height: 24px;
  background-position: -320px -209px;
}

.flag-is {
  width: 35px;
  height: 24px;
  background-position: -365px -209px;
}

.flag-it {
  width: 35px;
  height: 24px;
  background-position: -410px -209px;
}

.flag-jm {
  width: 35px;
  height: 24px;
  background-position: -455px -209px;
}

.flag-jo {
  width: 35px;
  height: 24px;
  background-position: -500px -209px;
}

.flag-jp {
  width: 35px;
  height: 24px;
  background-position: -5px -243px;
}

.flag-ke {
  width: 35px;
  height: 24px;
  background-position: -50px -243px;
}

.flag-kg {
  width: 35px;
  height: 24px;
  background-position: -95px -243px;
}

.flag-kh {
  width: 35px;
  height: 24px;
  background-position: -140px -243px;
}

.flag-ki {
  width: 35px;
  height: 24px;
  background-position: -185px -243px;
}

.flag-km {
  width: 35px;
  height: 24px;
  background-position: -230px -243px;
}

.flag-kn {
  width: 35px;
  height: 24px;
  background-position: -275px -243px;
}

.flag-kp {
  width: 35px;
  height: 24px;
  background-position: -320px -243px;
}

.flag-kr {
  width: 35px;
  height: 24px;
  background-position: -365px -243px;
}

.flag-ks {
  width: 35px;
  height: 24px;
  background-position: -410px -243px;
}

.flag-kw {
  width: 35px;
  height: 24px;
  background-position: -455px -243px;
}

.flag-kz {
  width: 35px;
  height: 24px;
  background-position: -500px -243px;
}

.flag-la {
  width: 35px;
  height: 24px;
  background-position: -5px -277px;
}

.flag-lb {
  width: 35px;
  height: 24px;
  background-position: -50px -277px;
}

.flag-lc {
  width: 35px;
  height: 24px;
  background-position: -95px -277px;
}

.flag-li {
  width: 35px;
  height: 24px;
  background-position: -140px -277px;
}

.flag-lk {
  width: 35px;
  height: 24px;
  background-position: -185px -277px;
}

.flag-lr {
  width: 35px;
  height: 24px;
  background-position: -230px -277px;
}

.flag-ls {
  width: 35px;
  height: 24px;
  background-position: -275px -277px;
}

.flag-lt {
  width: 35px;
  height: 24px;
  background-position: -320px -277px;
}

.flag-lu {
  width: 35px;
  height: 24px;
  background-position: -365px -277px;
}

.flag-lv {
  width: 35px;
  height: 24px;
  background-position: -410px -277px;
}

.flag-ly {
  width: 35px;
  height: 24px;
  background-position: -455px -277px;
}

.flag-ma {
  width: 35px;
  height: 24px;
  background-position: -500px -277px;
}

.flag-mc {
  width: 35px;
  height: 24px;
  background-position: -5px -311px;
}

.flag-md {
  width: 35px;
  height: 24px;
  background-position: -50px -311px;
}

.flag-me {
  width: 35px;
  height: 24px;
  background-position: -95px -311px;
}

.flag-mg {
  width: 35px;
  height: 24px;
  background-position: -140px -311px;
}

.flag-mh {
  width: 35px;
  height: 24px;
  background-position: -185px -311px;
}

.flag-mk {
  width: 35px;
  height: 24px;
  background-position: -230px -311px;
}

.flag-ml {
  width: 35px;
  height: 24px;
  background-position: -275px -311px;
}

.flag-mm {
  width: 35px;
  height: 24px;
  background-position: -320px -311px;
}

.flag-mn {
  width: 35px;
  height: 24px;
  background-position: -365px -311px;
}

.flag-mr {
  width: 35px;
  height: 24px;
  background-position: -410px -311px;
}

.flag-mt {
  width: 35px;
  height: 24px;
  background-position: -455px -311px;
}

.flag-mu {
  width: 35px;
  height: 24px;
  background-position: -500px -311px;
}

.flag-mv {
  width: 35px;
  height: 24px;
  background-position: -5px -345px;
}

.flag-mw {
  width: 35px;
  height: 24px;
  background-position: -50px -345px;
}

.flag-mx {
  width: 35px;
  height: 24px;
  background-position: -95px -345px;
}

.flag-my {
  width: 35px;
  height: 24px;
  background-position: -140px -345px;
}

.flag-mz {
  width: 35px;
  height: 24px;
  background-position: -185px -345px;
}

.flag-na {
  width: 35px;
  height: 24px;
  background-position: -230px -345px;
}

.flag-ne {
  width: 35px;
  height: 24px;
  background-position: -275px -345px;
}

.flag-ng {
  width: 35px;
  height: 24px;
  background-position: -320px -345px;
}

.flag-ni {
  width: 35px;
  height: 24px;
  background-position: -365px -345px;
}

.flag-nl {
  width: 35px;
  height: 24px;
  background-position: -410px -345px;
}

.flag-no {
  width: 35px;
  height: 24px;
  background-position: -455px -345px;
}

.flag-np {
  width: 35px;
  height: 24px;
  background-position: -500px -345px;
}

.flag-nr {
  width: 35px;
  height: 24px;
  background-position: -5px -379px;
}

.flag-nz {
  width: 35px;
  height: 24px;
  background-position: -50px -379px;
}

.flag-om {
  width: 35px;
  height: 24px;
  background-position: -95px -379px;
}

.flag-pa {
  width: 35px;
  height: 24px;
  background-position: -140px -379px;
}

.flag-pe {
  width: 35px;
  height: 24px;
  background-position: -185px -379px;
}

.flag-pg {
  width: 35px;
  height: 24px;
  background-position: -230px -379px;
}

.flag-ph {
  width: 35px;
  height: 24px;
  background-position: -275px -379px;
}

.flag-pk {
  width: 35px;
  height: 24px;
  background-position: -320px -379px;
}

.flag-pl {
  width: 35px;
  height: 24px;
  background-position: -365px -379px;
}

.flag-pt {
  width: 35px;
  height: 24px;
  background-position: -410px -379px;
}

.flag-pw {
  width: 35px;
  height: 24px;
  background-position: -455px -379px;
}

.flag-py {
  width: 35px;
  height: 24px;
  background-position: -500px -379px;
}

.flag-qa {
  width: 35px;
  height: 24px;
  background-position: -5px -413px;
}

.flag-ro {
  width: 35px;
  height: 24px;
  background-position: -50px -413px;
}

.flag-rs {
  width: 35px;
  height: 24px;
  background-position: -95px -413px;
}

.flag-ru {
  width: 35px;
  height: 24px;
  background-position: -140px -413px;
}

.flag-rw {
  width: 35px;
  height: 24px;
  background-position: -185px -413px;
}

.flag-sa {
  width: 35px;
  height: 24px;
  background-position: -230px -413px;
}

.flag-sb {
  width: 35px;
  height: 24px;
  background-position: -275px -413px;
}

.flag-sc {
  width: 35px;
  height: 24px;
  background-position: -320px -413px;
}

.flag-sd {
  width: 35px;
  height: 24px;
  background-position: -365px -413px;
}

.flag-se {
  width: 35px;
  height: 24px;
  background-position: -410px -413px;
}

.flag-sg {
  width: 35px;
  height: 24px;
  background-position: -455px -413px;
}

.flag-si {
  width: 35px;
  height: 24px;
  background-position: -500px -413px;
}

.flag-sk {
  width: 35px;
  height: 24px;
  background-position: -5px -447px;
}

.flag-sl {
  width: 35px;
  height: 24px;
  background-position: -50px -447px;
}

.flag-sm {
  width: 35px;
  height: 24px;
  background-position: -95px -447px;
}

.flag-sn {
  width: 35px;
  height: 24px;
  background-position: -140px -447px;
}

.flag-so {
  width: 35px;
  height: 24px;
  background-position: -185px -447px;
}

.flag-sr {
  width: 35px;
  height: 24px;
  background-position: -230px -447px;
}

.flag-ss {
  width: 35px;
  height: 24px;
  background-position: -275px -447px;
}

.flag-st {
  width: 35px;
  height: 24px;
  background-position: -320px -447px;
}

.flag-sv {
  width: 35px;
  height: 24px;
  background-position: -365px -447px;
}

.flag-sy {
  width: 35px;
  height: 24px;
  background-position: -410px -447px;
}

.flag-sz {
  width: 35px;
  height: 24px;
  background-position: -455px -447px;
}

.flag-td {
  width: 35px;
  height: 24px;
  background-position: -500px -447px;
}

.flag-tg {
  width: 35px;
  height: 24px;
  background-position: -5px -481px;
}

.flag-th {
  width: 35px;
  height: 24px;
  background-position: -50px -481px;
}

.flag-tj {
  width: 35px;
  height: 24px;
  background-position: -95px -481px;
}

.flag-tl {
  width: 35px;
  height: 24px;
  background-position: -140px -481px;
}

.flag-tm {
  width: 35px;
  height: 24px;
  background-position: -185px -481px;
}

.flag-tn {
  width: 35px;
  height: 24px;
  background-position: -230px -481px;
}

.flag-to {
  width: 35px;
  height: 24px;
  background-position: -275px -481px;
}

.flag-tr {
  width: 35px;
  height: 24px;
  background-position: -320px -481px;
}

.flag-tt {
  width: 35px;
  height: 24px;
  background-position: -365px -481px;
}

.flag-tv {
  width: 35px;
  height: 24px;
  background-position: -410px -481px;
}

.flag-tw {
  width: 35px;
  height: 24px;
  background-position: -455px -481px;
}

.flag-tz {
  width: 35px;
  height: 24px;
  background-position: -500px -481px;
}

.flag-ua {
  width: 35px;
  height: 24px;
  background-position: -5px -515px;
}

.flag-ug {
  width: 35px;
  height: 24px;
  background-position: -50px -515px;
}

.flag-unknown {
  width: 35px;
  height: 24px;
  background-position: -95px -515px;
}

.flag-us {
  width: 35px;
  height: 24px;
  background-position: -140px -515px;
}

.flag-uy {
  width: 35px;
  height: 24px;
  background-position: -185px -515px;
}

.flag-uz {
  width: 35px;
  height: 24px;
  background-position: -230px -515px;
}

.flag-va {
  width: 35px;
  height: 24px;
  background-position: -275px -515px;
}

.flag-vc {
  width: 35px;
  height: 24px;
  background-position: -320px -515px;
}

.flag-ve {
  width: 35px;
  height: 24px;
  background-position: -365px -515px;
}

.flag-vn {
  width: 35px;
  height: 24px;
  background-position: -410px -515px;
}

.flag-vu {
  width: 35px;
  height: 24px;
  background-position: -455px -515px;
}

.flag-ws {
  width: 35px;
  height: 24px;
  background-position: -500px -515px;
}

.flag-ye {
  width: 35px;
  height: 24px;
  background-position: -545px -5px;
}

.flag-za {
  width: 35px;
  height: 24px;
  background-position: -545px -39px;
}

.flag-zm {
  width: 35px;
  height: 24px;
  background-position: -545px -73px;
}

.flag-zw {
  width: 35px;
  height: 24px;
  background-position: -545px -107px;
}