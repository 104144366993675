@font-face {
  font-family: 'Simple-Line-Icons';
  src: url('../fonts/Simple-Line-Icons.eot');
  src: url('../fonts/Simple-Line-Icons.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Simple-Line-Icons.woff') format('woff'),
  url('../fonts/Simple-Line-Icons.ttf') format('truetype'),
  url('../fonts/Simple-Line-Icons.svg#Simple-Line-Icons') format('svg');
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'weathericons';
  src: url('../fonts/weathericons-regular-webfont.eot');
  src: url('../fonts/weathericons-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/weathericons-regular-webfont.woff') format('woff'), url('../fonts/weathericons-regular-webfont.ttf') format('truetype'), url('../fonts/weathericons-regular-webfont.svg#weathericons-regular-webfontRg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.myIcon {
  display: inline-block;
  font-size: 0px;
  cursor: pointer;
  margin: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: #fff;

  &:hover {
    text-decoration: none;
  }

  >.fa {
    speak: none;
    font-size: 16px;
    line-height: 50px;
    display: block;
  }

  &:after,
  &:before {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    @include box-sizing(content-box);
  }

  &:before {
    display: none;
  }

  // effects


  /* Effect 1 */
  &.icon-ef-1 {
    @include transition(background 0.2s, color 0.2s);

    &:after {
      top: -5px;
      left: -5px;
      padding: 5px;
      box-shadow: 0 0 0 3px;
      @include transition(transform 0.2s, opacity 0.2s);
      @include transform(scale(.8));
      @include opacity(0);
    }

    &:before {
      display: none;
    }

    /* Effect 1a */
    &.icon-ef-1a {
      &:hover {
        &:after {
          @include transform(scale(1));
          @include opacity(1);
        }
      }
    }

    /* Effect 1b */
    &.icon-ef-1b {

      &:after {
        @include transform(scale(1.2));
      }

      &:hover {
        &:after {
          @include transform(scale(1));
          @include opacity(1);
        }
      }
    }
  }

  /* Effect 2 */
  &.icon-ef-2 {
    background-color: transparent !important;
    @include transition(color 0.3s);

    &:after {
      box-shadow: 0 0 0 3px;
      left: 0;
      top: 0;
    }

    &:before {
      display: block;
      top: -2px;
      left: -2px;
      padding: 2px;
      z-index: -1;
      @include transition(transform 0.2s, opacity 0.2s);
    }

    /* Effect 2a */
    &.icon-ef-2a {
      &:hover {
        &:before {
          @include transform(scale(0.85));
        }
      }
    }

    /* Effect 2b */
    &.icon-ef-2b {
      &:hover {
        &:before {
          @include transform(scale(0));
          @include transition(transform 0.4s, opacity 0.2s);
          opacity: 0;
        }
      }
    }
  }

  /* Effect 3 */
  &.icon-ef-3 {
    background-color: transparent !important;
    @include transition(color 0.3s);

    &:after {
      box-shadow: 0 0 0 3px;
      left: 0;
      top: 0;
    }

    &:before {
      display: block;
      top: -2px;
      left: -2px;
      padding: 2px;
      z-index: -1;
      @include transition(transform 0.2s, opacity 0.3s);
    }

    /* Effect 3a */
    &.icon-ef-3a {
      &:hover {
        &:before {
          @include transform(scale(1.3));
          @include opacity(0);
        }
      }
    }

    /* Effect 3b */
    &.icon-ef-3b {
      &:before {
        @include transform(scale(1.3));
        @include opacity(0);
      }
      &:hover {
        color: white!important;

        &:before {
          @include transform(scale(1));
          @include opacity(1);
        }
      }
    }
  }

  /* Effect 4 */
  &.icon-ef-4 {
    background-color: transparent !important;
    box-shadow: 0 0 0 3px;

    &:after {
      top: -3px;
      left: -3px;
      padding: 0;
      z-index: 10;
      border: 3px dashed;
    }

    &:before {
      display: none;
    }

    &:hover {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }

    /* Effect 4a */
    &.icon-ef-4a {
      @include transition(box-shadow 0.2s);
    }

    /* Effect 4b */
    &.icon-ef-4b {
     &:hover {
       @include transition(box-shadow 0.2s);

       &:after {
         @include animation(spinAround 9s linear infinite);
       }
     }
    }
  }

  /* Effect 5 */
  &.icon-ef-5 {
    background-color: transparent !important;

    &:after {
      top: 0;
      left: 0;
      box-shadow: 0 0 0 3px;
      @include transition(box-shadow 0.3s);
    }

    &:before {
      display: block;
      top: -2px;
      left: -2px;
      padding: 2px;
      display: none;
      z-index: -1;
      @include transition(background 0.3s);
    }

    &:hover {
      >.fa {
        color: white!important;
      }
      &:before {
        display: block;
      }

      &:after {
        box-shadow: 0 0 0 6px;
        @include opacity(.3);
      }
    }

    /* Effect 5a */
    &.icon-ef-5a {
      &:hover {
        >.fa {
           @include animation(toRightFromLeft .3s forwards);
        }
      }
    }

    /* Effect 5b */
    &.icon-ef-5b {
      &:hover {
        >.fa {
           @include animation(toLeftFromRight .3s forwards);
        }
      }
    }

    /* Effect 5c */
    &.icon-ef-5c {
      &:hover {
        >.fa {
           @include animation(toTopFromBottom .3s forwards);
        }
      }
    }

    /* Effect 5d */
    &.icon-ef-5d {
      &:hover {
        >.fa {
           @include animation(toBottomFromTop .3s forwards);
        }
      }
    }
  }

  /* Effect 6 */
  &.icon-ef-6 {
    background-color: transparent !important;
    @include transition(color .2s);

    &:after {
      top: 0;
      left: 0;
      box-shadow: 0 0 0 3px;
    }

    &:before {
      display: block;
      top: -2px;
      left: -2px;
      padding: 2px;
      @include opacity(0);
      @include transition(opacity .2s);
    }

    &:hover {
      color: white !important;
      >.fa {
        @include animation(spinAround 2s linear infinite);
      }

      &:before {
        @include opacity(1);
      }
    }
  }

  /* Effect 7 */
  &.icon-ef-7 {
    background-color: transparent !important;
    @include transition(color .2s);
    box-shadow: 0 0 0 3px;

    &:after {
      top: -6px;
      left: -6px;
      padding: 6px;
      z-index: -1;
      @include opacity(0);
    }

    &:before {
      display: none;
    }

    > .fa {
      @include transform(scale(0.8));
      @include transition(transform 0.2s, opacity 0.2s);
      @include opacity(.7);
    }

    &:hover {
      > .fa {
        @include transform(scale(1));
        @include opacity(1);
      }
    }

    /* Effect 7a */
    &.icon-ef-7a {

      &:after {
        box-shadow: 0 0 0;
        @include transition(opacity 0.2s, box-shadow 0.2s);
      }

      &:hover {
        &:after {
          @include opacity(1);
          box-shadow: 2px 2px 0;
        }
      }
    }

    /* Effect 7b */
    &.icon-ef-7b {

      &:after {
        box-shadow: 2px 2px;
        @include transform(rotate(-90deg));
        @include transition(opacity 0.2s, transform 0.2s);
      }

      &:hover {
        &:after {
          @include opacity(1);
          @include transform(rotate(0));
        }
      }
    }
  }

  /* Effect 8 */
  &.icon-ef-8 {
    background: transparent !important;
    @include transition(transform ease-out 0.1s, background 0.2s);

    &:after {
      top: 0;
      left: 0;
      padding: 0;
      z-index: -1;
      box-shadow: 0 0 0 2px;
      opacity: 0;
    }

    &:before {
      display: block;
      top: 0;
      left: 0;
      @include opacity(.1);
    }

    &:hover {
      @include transform(scale(0.93));

      &:before {
        @include opacity(.05);
      }

      &:after {
        @include animation(sonarEffect 1.3s ease-out 75ms);
      }
    }
  }

  /* Effect 9 */
  &.icon-ef-9 {
    background-color: transparent !important;
    @include transition(box-shadow 0.2s);

    &:after {
      top: 0;
      left: 0;
      padding: 0;
      box-shadow: 0 0 0 3px;
      @include transition(transform 0.2s, opacity 0.2s);
    }

    &:before {
      display: none;
    }

    &:hover {
      box-shadow: 0 0 0 5px;

      &:after {
        @include opacity(.3);
        @include transform(scale(0.8));
      }
    }
  }



  // colors

  &.icon-cyan {
    background-color: $cyan;
    color: white;

    &.transparent {
      background-color: transparentize($cyan, .9);
      color: $cyan;

      &:hover {
        background-color: $cyan;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $cyan;
    }

    &:before {
      background-color: $cyan;
    }

    &:hover {
      background-color: $darken-cyan;
    }

    &:active {
      background-color: $darker-cyan;
    }

  }
  &.icon-amethyst {
    background-color: $amethyst;
    color: white;

    &.transparent {
      background-color: transparentize($amethyst, .9);
      color: $amethyst;

      &:hover {
        background-color: $amethyst;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $amethyst;
    }

    &:before {
      background-color: $amethyst;
    }

    &:hover {
      background-color: $darken-amethyst;
    }

    &:active {
      background-color: $darker-amethyst;
    }

  }
  &.icon-green {
    background-color: $green;
    color: white;

    &.transparent {
      background-color: transparentize($green, .9);
      color: $green;

      &:hover {
        background-color: $green;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $green;
    }

    &:before {
      background-color: $green;
    }

    &:hover {
      background-color: $darken-green;
    }

    &:active {
      background-color: $darker-green;
    }

  }
  &.icon-orange {
    background-color: $orange;
    color: white;

    &.transparent {
      background-color: transparentize($orange, .9);
      color: $orange;

      &:hover {
        background-color: $orange;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $orange;
    }

    &:before {
      background-color: $orange;
    }

    &:hover {
      background-color: $darken-orange;
    }

    &:active {
      background-color: $darker-orange;
    }

  }
  &.icon-red {
    background-color: $red;
    color: white;

    &.transparent {
      background-color: transparentize($red, .9);
      color: $red;

      &:hover {
        background-color: $red;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $red;
    }

    &:before {
      background-color: $red;
    }

    &:hover {
      background-color: $darken-red;
    }

    &:active {
      background-color: $darker-red;
    }

  }
  &.icon-greensea {
    background-color: $greensea;
    color: white;

    &.transparent {
      background-color: transparentize($greensea, .9);
      color: $greensea;

      &:hover {
        background-color: $greensea;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $greensea;
    }

    &:before {
      background-color: $greensea;
    }

    &:hover {
      background-color: $darken-greensea;
    }

    &:active {
      background-color: $darker-greensea;
    }

  }
  &.icon-dutch {
    background-color: $dutch;
    color: white;

    &.transparent {
      background-color: transparentize($dutch, .9);
      color: $dutch;

      &:hover {
        background-color: $dutch;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $dutch;
    }

    &:before {
      background-color: $dutch;
    }

    &:hover {
      background-color: $darken-dutch;
    }

    &:active {
      background-color: $darker-dutch;
    }

  }
  &.icon-hotpink {
    background-color: $hotpink;
    color: white;

    &.transparent {
      background-color: transparentize($hotpink, .9);
      color: $hotpink;

      &:hover {
        background-color: $hotpink;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $hotpink;
    }

    &:before {
      background-color: $hotpink;
    }

    &:hover {
      background-color: $darken-hotpink;
    }

    &:active {
      background-color: $darker-hotpink;
    }

  }
  &.icon-drank {
    background-color: $drank;
    color: white;

    &.transparent {
      background-color: transparentize($drank, .9);
      color: $drank;

      &:hover {
        background-color: $drank;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $drank;
    }

    &:before {
      background-color: $drank;
    }

    &:hover {
      background-color: $darken-drank;
    }

    &:active {
      background-color: $darker-drank;
    }

  }
  &.icon-blue {
    background-color: $blue;
    color: white;

    &.transparent {
      background-color: transparentize($blue, .9);
      color: $blue;

      &:hover {
        background-color: $blue;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $blue;
    }

    &:before {
      background-color: $blue;
    }

    &:hover {
      background-color: $darken-blue;
    }

    &:active {
      background-color: $darker-blue;
    }

  }
  &.icon-lightred {
    background-color: $lightred;
    color: white;

    &.transparent {
      background-color: transparentize($lightred, .9);
      color: $lightred;

      &:hover {
        background-color: $lightred;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $lightred;
    }

    &:before {
      background-color: $lightred;
    }

    &:hover {
      background-color: $darken-lightred;
    }

    &:active {
      background-color: $darker-lightred;
    }

  }
  &.icon-slategray {
    background-color: $slategray;
    color: white;

    &.transparent {
      background-color: transparentize($slategray, .9);
      color: $slategray;

      &:hover {
        background-color: $slategray;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $slategray;
    }

    &:before {
      background-color: $slategray;
    }

    &:hover {
      background-color: $darken-slategray;
    }

    &:active {
      background-color: $darker-slategray;
    }

  }
  &.icon-darkgray {
    background-color: $darkgray;
    color: white;

    &.transparent {
      background-color: transparentize($darkgray, .9);
      color: $darkgray;

      &:hover {
        background-color: $darkgray;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $darkgray;
    }

    &:before {
      background-color: $darkgray;
    }

    &:hover {
      background-color: $darken-darkgray;
    }

    &:active {
      background-color: $darker-darkgray;
    }

  }

  &.icon-primary {
    background-color: $brand-primary;
    color: white;

    &.transparent {
      background-color: transparentize($brand-primary, .9);
      color: $brand-primary;

      &:hover {
        background-color: $brand-primary;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $brand-primary;
    }

    &:before {
      background-color: $brand-primary;
    }

    &:hover {
      background-color: darken($brand-primary, 10%);
    }

    &:active {
      background-color: darken($brand-primary, 20%);
    }

  }

  &.icon-success {
    background-color: $brand-success;
    color: white;

    &.transparent {
      background-color: transparentize($brand-success, .9);
      color: $brand-success;

      &:hover {
        background-color: $brand-success;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $brand-success;
    }

    &:before {
      background-color: $brand-success;
    }

    &:hover {
      background-color: darken($brand-success, 10%);
    }

    &:active {
      background-color: darken($brand-success, 20%);
    }


  }

  &.icon-warning {
    background-color: $brand-warning;
    color: white;

    &.transparent {
      background-color: transparentize($brand-warning, .9);
      color: $brand-warning;

      &:hover {
        background-color: $brand-warning;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $brand-warning;
    }

    &:before {
      background-color: $brand-warning;
    }

    &:hover {
      background-color: darken($brand-warning, 10%);
    }

    &:active {
      background-color: darken($brand-warning, 20%);
    }


  }

  &.icon-danger {
    background-color: $brand-danger;
    color: white;

    &.transparent {
      background-color: transparentize($brand-danger, .9);
      color: $brand-danger;

      &:hover {
        background-color: $brand-danger;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $brand-danger;
    }

    &:before {
      background-color: $brand-danger;
    }

    &:hover {
      background-color: darken($brand-danger, 10%);
    }

    &:active {
      background-color: darken($brand-danger, 20%);
    }


  }

  &.icon-info {
    background-color: $brand-info;
    color: white;

    &.transparent {
      background-color: transparentize($brand-info, .9);
      color: $brand-info;

      &:hover {
        background-color: $brand-info;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $brand-info;
    }

    &:before {
      background-color: $brand-info;
    }

    &:hover {
      background-color: darken($brand-info, 10%);
    }

    &:active {
      background-color: darken($brand-info, 20%);
    }


  }

  &.icon-default {
    background-color: $brand-default;
    color: white;

    &.transparent {
      background-color: transparentize($brand-default, .9);
      color: $brand-default;

      &:hover {
        background-color: $brand-default;
        color: white;
      }
    }

    &:after,
    &.hover-color:hover,
    &.icon-color {
      color: $brand-default;
    }

    &:before {
      background-color: $brand-default;
    }

    &:hover {
      background-color: darken($brand-default, 10%);
    }

    &:active {
      background-color: darken($brand-default, 20%);
    }


  }
}

// icon list

.icons-list {
  div {
    line-height: 40px;
    white-space: nowrap;
    cursor: default;
    position: relative;
    z-index: 1;

    &::after {
      content: '';
      position: absolute;
      left: 15px;
      right: 0;
      width: 85%;
      height: 100%;
      background-color: #f8f8f8;
      z-index: -1;
      @include opacity(0);
      @include transition(opacity .2s, transform .4s);
      @include transform(scale(.5));
    }

    i {
      display: inline-block;
      width: 40px;
      margin: 0;
      text-align: center;
      vertical-align: middle;
      @include transition(font-size .2s);
    }

    &:hover {
      &::after {
        @include opacity(1);
        @include transform(scale(1));
      }
      i {
        font-size: 26px;
      }
    }
  }
}

// bordered icon

.icon-border {
  border: 1px solid;
  border-radius: 50%;
}
.icon-border-br-5 {
  border: 1px solid;
  border-radius: 5px;
}

@font-face {
  font-family: 'Glyphicons Halflings';

  src: url('../fonts/glyphicons-halflings-regular.eot');
  src: url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/glyphicons-halflings-regular.woff') format('woff'), url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}