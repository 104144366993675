/* app css stylesheet */
@import "flags";
@import "games";
@import "yatri/main";


body {
  font-family: 'Catamaran', sans-serif;
  font-weight: 400 !important;
  background-color: #fafbfc;
}

.clr {
  clear: both;
}

.dropdown {
  &.users i {
    color: #ffd338 !important;
  }
  &.notifications i {
    color: #bec9d4;
  }
  &.nav-profile {
    .dropdown-menu {
      left: -36px;
      i {
        color: #92aecb;
      }
    }
    .dropdown-toggle {
      /*padding-right:0px!important;*/
    }
  }
}

.notify {
  .point {
    background-color: #9ba7b5;
  }
  .heartbit {
    border-color: #bec9d4;
  }
}

.dropdown.nav-profile {
  span i {
    position: relative;
    top: 3px;
  }
}

.avatar-placeholder {
  margin:auto auto;
  display: inline-block;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  border-radius: 100%;
  /* fallback */
  background-color: #1a82f7;
  /* Safari 4-5, Chrome 1-9 */
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ffb764), to(#ff706d));
  /* Safari 5.1, Chrome 10+ */
  background: -webkit-linear-gradient(top, #ffb764, #ff706d);
  /* Firefox 3.6+ */
  background: -moz-linear-gradient(top, #ffb764, #ff706d);
  /* IE 10 */
  background: -ms-linear-gradient(top, #ffb764, #ff706d);
  /* Opera 11.10+ */
  background: -o-linear-gradient(top, #ffb764, #ff706d);
}

.avatar-placeholder-sm {
  width: 30px;
  height: 30px;
  line-height: 30px !important;
  font-size: 13px;
}

.avatar-placeholder-lg {
  width: 214px;
  height: 214px;
  line-height: 214px !important;
  font-size: 93px;
}

.avatar {
  text-align:center;
}

#header {
  &.scheme-light {
    .nav-right > li > a i, .nav-left > li > a i {
      color: #b0bbc7;
      font-size: 20px;
    }
  }
  .branding {
    background-color: #223656;
  }
}

#sidebar {
  background-color: #223656;
}

#navigation {
  background-color: #223656;
  > li.active > a {
    background-color: #fafbfc !important;
    color: #223656 !important;
  }
}

.appWrapper.amethyst-scheme-color {
  #navigation > li.active > a, #loading-bar .bar {
    background-color: #fafbfc !important;
    color: #223656 !important;
  }
}

#navigation {
  > li {
    &.open > a, &:hover > a {
      background-color: #223656;
      color: #ffffff;
    }
  }
  .dropdown > ul {
    background-color: #394a67;
  }
}

.appWrapper.amethyst-scheme-color #navigation .dropdown > ul li {
  &:hover > a, &.active > a {
    color: #fff !important;
  }
  &.active > a {
    color: #44ce7b !important;
    font-weight: bold;
  }
  > a {
    &:hover, &.active {
      color: #44ce7b !important;
    }
  }
}

#navigation {
  .dropdown {
    > {
      a > i:last-of-type {
        left: 12px;
        top: 19px;
      }
      ul li > a > {
        .badge, .label {
          top: 2px;
        }
      }
    }
    > ul {
      padding-top: 24px;
      li {
        padding-bottom: 5px;
        > a {
          position: relative;
          padding: 0 0 8px 56px;
          color: #fff;
          i {
            display: none;
          }
          &:before {
            content: "";
            display: block;
            width: 6px;
            height: 13px;
            background: url(../imgs/left-sidebar-nav-bg.png) no-repeat 0 0;
            position: absolute;
            left: 40px;
            top: 5px;
          }
        }
        &:last-child {
          padding-bottom: 10px;
          a:before {
            height: 10px;
          }
        }
      }
    }
  }
  > li > a {
    > {
      .badge {
        left: 42px;
      }
      i {
        text-align: left;
        width: 10px;
      }
    }
    padding: 15px 20px 15px 32px;
    font-size: 15px;
  }
}

.alert.alert-warning {
  background: #fff;
  border: 1px solid #ffdc70;
  color: #4d585f;
  padding: 0px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05), 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  .icon-holder {
    width: 55px;
    line-height: 63px;
    background-color: #ffce38;
    color: #fff;
    float: left;
    border-radius: 4px 0 0 4px;
    text-align: center;
    font-size: 22px;
  }
  .txt {
    float: left;
    width: calc(100% - 55px);
    float: left;
    padding: 10px 13px;
  }
}

.pageheader h2 {
  color: #3f3f3f;
  font-size: 22px;
  font-weight: 600;
  span {
    font-weight: 400;
  }
}

.grn-clr {
  color: #44ce7b !important;
}

.red-clr {
  color: #fc4245 !important;
}

.card-container .card .front.bg-white {
  min-height: 188px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05), 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  .card-option {
    position: absolute;
    right: 0px;
    top: 0px;
    .dropdown-toggle i {
      color: #a1b9d2;
      font-size: 18px;
    }
    .dropdown-menu {
      left: -126px;
      top: 75%;
    }
    .dropdown-toggle {
      display: inline-block;
      padding: 15px;
    }
  }
  h5 {
    color: #92aecb;
    font-size: 15px;
    font-weight: 600;
    padding: 5px 0 25px;
    margin: 0px;
  }
  p {
    color: #4e4e4e;
    padding: 0 0 12px;
    margin: 0px;
    font-size: 35px !important;
    font-weight: 400;
    position: relative;
    .icon {
      position: absolute;
      left: -49px;
      top: 0px;
    }
  }
  span {
    color: #9ba7b5;
    font-size: 14px;
    font-weight: 600;
  }
}

.boxs {
  &.custom {
    padding-bottom: 13px;
    margin-bottom: 30px;
    .boxs-header {
      padding: 24px 21px;
      h1 {
        color: #3f3f3f;
        font-size: 22px;
        font-weight: 600;
        span.date{
          font-size:12px;
          float:right;
          font-weight: normal;
        }
        span.good {
          display: block;
          font-size: 16px;
          color: #44cd7d;
          padding-left: 38px;
        }
        span.bad {
          display: block;
          font-size: 16px;
          color: #FF6347;
          padding-left: 38px;
        }
        label.txt {
          display: block;
          font-size: 13px;
          color: #5f6773;
          font-weight: 400;
          line-height: 18px;
          padding: 15px 0 0;
          margin: 0px;
        }
        i {
          position: relative;
          display: inline-block;
          color: #a1b9d2;
          margin-right: 10px;
        }
      }
      .controls {
        right: 7px;
        top: 13px;
        height: auto;
        li {
          a {
            height: auto;
          }
          .settings i {
            color: #a1b9d2;
            font-size: 18px;
            position: relative;
            top: 3px;
          }
        }
        select {
          font-size: 12px;
          width: 150px;
          color: #787878;
          position: relative;
          top: 11px;
        }
      }
    }
  }


  .boxs-header, .boxs-widget, .boxs-body, .boxs-footer {
    padding: 15px 21px;
  }

  .boxs-graph {
    height: 400px;
  }

  &.custom .boxs-header .controls {
    .dropdown.menu {
      border: 1px solid #b3c3d0;
      border-radius: 2px;
      a {
        font-size: 13px;
        padding: 12px 16px 8px;
        line-height: inherit;
      }
    }
    li {
      margin-left: 10px;
      &:last-child {
        margin-left: 0px;
      }
    }
  }
  &.statistics {
    .stats {
    }
    .boxs-body p {
      padding: 0 0 20px;
    }
    .stats .stat {
      text-align: center;
      border: 1px solid #eeeff8;
      border-radius: 5px;
      padding: 40px 10px 41px;
      cursor: pointer;
      position: relative;
      box-shadow: 0 1px 10px 0 rgba(147, 157, 199, 0.1), 0 1px 10px 0 rgba(147, 157, 199, 0.1);
      margin-bottom: 39px;
      .badge {
        display: block;
        width: 72px;
        height: 20px;
        line-height: 16px;
        text-align: center;
        position: absolute;
        top: 8px;
        right: 9px;
        border-radius: 2px;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
        display: none;
        background-color: #56d1ff;
      }
      &:hover {
        border-color: #56d1ff;
        background-color: #f8fdff;
        .badge {
          display: block;
        }
        h5 {
          color: #56d1ff;
          span {
            color: #56d1ff;
          }
        }
      }
      h5 {
        padding: 0px;
        margin: 0px;
        font-size: 35px;
        color: #77747d;
        font-weight: 400;
        span {
          display: block;
          font-size: 16px;
          font-weight: 600;
          color: #92aecb;
          padding: 12px 0 0;
        }
      }
    }
  }
  &.top-countries {
    .boxs-body {
      /*padding-top:0px;*/
      padding-bottom: 0px;
    }
    .table-responsive table {
      tr:first-child td {
        border: 0px;
      }
      th {
        font-size: 16px;
        color: #3f3f3f;
        border: 0px;
        padding-bottom: 0px;
        padding-left: 10px;
      }
      td {
        padding-left: 10px;
        img {
          position: relative;
          top: -2px;
          margin-right: 5px;
        }
        font-size: 13px;
        color: #3f3f3f;
        line-height: 20px;
      }
    }
  }
}

.dropdown-menu {
  padding-top: 0px;
  li {
    margin: 0px !important;
  }
  i {
    color: #92aecb;
  }
  > li > a {
    &:focus, &:hover {
      background-color: #7fc7ff;
      color: #fff;
    }
  }
}

.sidebar-sm {
  #sidebar a > i, #navigation a > i {
    width: 100%;
    text-align: center;
  }
}

#sidebar .panel {
  height: 100%;
  #sidebarNav {
    height: 100%;
    .panel-body {
      height: 100%;
    }
  }
}

#spline-graph .highcharts-container {
  width: 100% !important;
}

.h-highlight-tbl {
  border: 1px solid #dee7ee;
  border-radius: 2px;
  thead {
  }
  tr td {
    &:last-child {
      padding-right: 8px !important;
    }
    &:first-child {
      text-align: left;
    }
  }
  thead th:first-child {
    text-align: left;
  }
  tr td {
    p {
      padding: 0 0 5px !important;
    }
    img {
      position: relative;
      top: -2px;
    }
  }
  thead th {
    background-color: #f5f8fa;
    border: 1px solid #e3eaef !important;
  }
  th, td, a {
    text-align: center;
    color: #233340;
  }
  td {
    .dropdown {
      ul {
        left: -55px !important;
        top: 110% !important;
      }
      li {
        text-align: left;
        a {
          text-align: left;
        }
      }
    }
    border: 1px solid #e3eaef !important;
    .bullet {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      &.green {
        background-color: #77e0b5;
      }
      &.red {
        background-color: #f26d83;
      }
      &.yellow {
        background-color: #fee959;
      }
      &.pink {
        background-color: #FF99FF;
      }
      &.purple {
        background-color: #B069AF;
      }
      &.blue {
        background-color: #00aaff;
      }
      &.orange {
        background-color: #d58512;
      }
    }
  }
}

.page-profile {
  .boxs-header {
    padding: 24px 21px 18px;
    h1 {
      border-bottom: 1px solid #e0e8f0;
      display: block;
      width: 100%;
      padding: 0 0 15px;
      font-size: 20px !important;
    }
  }
  .edit-avatar .avatar {
    .img {
      padding: 0 0 15px;
      img {
        width: 100%;
        max-width: 214px;
        margin: auto;
        display: block;
        border-radius: 100%;
      }
    }
    .name {
      padding: 0 0 26px;
      margin: 0px;
      font-weight: 600;
      color: #394a67;
      font-size: 20px;
      text-align: center;
      span {
        display: block;
        color: #9ba7b5;
        font-size: 18px;
        font-weight: 400;
        padding: 5px 0 0;
      }
    }
  }
  input.btn, a.btn {
    width: 100%;
    max-width: 204px;
    text-align: center;
    margin: auto auto 10px;
    float: none;
    display: block;
    font-size: 16px;
    color: #3f3f3f;
    font-weight: 400;
    border: 1px solid #9ba7b5;
    background-color: transparent;
    padding: 10px 12px;
  }
  input.btn:hover, a.btn:hover {
    color: #fff;
    background-color: #44ce7b !important;
    border-color: #44ce7b !important;
  }
  .edit-settings {
  }
  form {
    .form-group {
      margin: 0 0 34px;
    }
    label {
      color: #394a67;
      font-size: 16px;
      font-weight: 600;
      margin: 0 0 2px;
    }
    .form-control {
      font-size: 14px;
      font-weight: 400;
      color: #394a67;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      border-radius: 0px;
      padding: 6px 0;
      option {
        padding-left: 0px;
      }
    }
    .checkbox-custom {
      font-weight: 400;
      font-size: 12px;
      margin: 0 0 20px;
    }
    .change-password h5 {
      color: #3f3f3f;
      font-size: 20px;
      padding: 0 0 18px;
      margin: 0 0 40px;
      font-weight: 600;
      border-bottom: 1px solid #ccd9e6;
      i {
        color: #b0bbc7;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
}

#mapBox .selector {
  #btn-prev-map, #btn-next-map {
    display: none;
  }
}

.form-control:focus {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.payment-setting form input.btn {
  display: inline-block;
  margin: 0 5px 15px;
}

.payment-detail-list {
  ul {
    padding: 0px;
    margin: 0px;
  }
  li {
    list-style: none;
    border-bottom: 1px solid #e0e8f0;
    margin: 0 0 30px;
    &:last-child {
      border: 0px;
      .btn {
        margin: 0px;
        background-color: #44ce7b;
        border-color: #44ce7b;
        color: #fff;
      }
    }
    i {
      color: #a1b9d2;
      font-size: 18px;
    }
    h5 {
      color: #3f3f3f;
      font-size: 16px;
      font-weight: 600;
      padding: 0 0 8px;
      margin: 0px;
    }
    p {
      padding: 0px;
      margin: 0px;
      color: #5f6773;
      font-size: 13px;
      font-weight: 400;
    }
    .text-right-section {
      padding-bottom: 20px;
      img {
        display: inline-block;
        margin-right: 15px;
      }
    }
    .text-left-section {
      padding-bottom: 20px;
    }
  }
  .refund-info {
    margin: 0 0 30px;
    .title {
      padding: 0 0 15px;
      margin: 0 0 20px;
      font-weight: 600;
      color: #3f3f3f;
      font-size: 22px;
      i {
        position: relative;
        display: inline-block;
        color: #a1b9d2;
        margin-right: 10px;
      }
    }
    .refunds {
      background: #f5f8fa;
      border-radius: 5px;
      ul {
        padding: 0px;
        margin: 0px;
      }
      li {
        border-bottom: 1px solid #e3eaef;
        list-style: none;
        padding: 10px 0;
        margin: 0px;
        &:last-child {
          border-bottom: 0px;
        }
        .left-txt {
          font-weight: 600;
          font-size: 16px;
          color: #3f3f3f;
          width: 200px;
          padding: 0 15px;
          float: left;
        }
        .right-txt {
          font-weight: 600;
          font-size: 13px;
          color: #5f6773;
          width: calc(100% - 200px);
          padding: 3px 15px 0;
          float: left;
        }
      }
    }
  }
}

.ad-settings .listed-bgs {
  .row {
    margin: 0 -7.5px;
  }
  .col-md-3 {
    padding: 0 7.5px;
  }
  .bg {
    position: relative;
    max-width: 140px;
    width: 100%;
    cursor: pointer;
    margin: auto auto 15px;
    img {
      width: 100%;
      position: relative;
      z-index: 1;
    }
    .tick {
      background: url(../imgs/checked-img.jpg) no-repeat 0px 0px;
      width: 21px;
      height: 20px;
      position: absolute;
      right: 6px;
      bottom: 6px;
      z-index: 2;
      display: none;
    }
    &.active .tick {
      display: block;
    }
    .fileContainer {
      overflow: hidden;
      position: relative;
      [type=file] {
        cursor: inherit;
        display: block;
        font-size: 999px;
        filter: alpha(opacity=0);
        min-height: 100%;
        min-width: 100%;
        opacity: 0;
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;
      }
      img {
        width: 100%;
        position: relative;
        cursor: pointer;
      }
      [type=file] {
        cursor: pointer;
      }
    }
  }
}

/* Example stylistic flourishes */

form .color-container {
  padding: 6px 0 5px;
  //border-bottom: 1px solid #e1e4e9;
}

.color-option .color {
  &.green {
    background-color: #44ce7b;
  }
  &.navy-blue {
    background-color: #223656;
  }
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  border-radius: 100%;
}

.btn-success {
  background-color: #44ce7b !important;
  border-color: #44ce7b !important;
  color: #fff !important;
}

.btn-danger {
  background-color: #d74b4b !important;
  border-color: #d74b4b !important;
  color: #fff !important;
}

.table-options a {
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid #a1b9d2;
  margin-left: 5px;
  margin-bottom: 5px;
  color: #a1b9d2;
  font-size: 12px;
  i {
    color: #a1b9d2;
  }
}

#accordion {
  .panel-title {
    color: #3f3f3f;
    font-size: 16px;
    font-weight: 600;
    a {
      font-size: 16px;
    }
    span {
      font-size: 13px;
      font-weight: 400;
      color: #3f3f3f;
    }
    i {
      color: #a1b9d2;
      margin-right: 10px;
      font-size: 18px;
    }
  }
  .panel-body {
    ul {
      padding: 0px;
      margin: 0px;
    }
    li {
      list-style: none;
      padding: 15px;
      font-size: 14px;
      margin: 0 0 5px;
      background-color: #fafafa;
      border-radius: 5px;
      &:last-child {
        margin-bottom: 0px;
      }
      .left-txt {
        float: left;
        width: 80px;
        font-weight: 600;
      }
      .right-txt {
        float: left;
        width: calc(100% - 80px);
        font-weight: 400;
        h5 {
          color: #3f3f3f;
          font-size: 14px;
          font-weight: 400;
          padding: 0 0 5px;
          margin: 0px;
        }
        p {
          font-size: 13px;
        }
        a {
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
}

.flag_show {
  display: inline-block;
  width: 16px;
  height: 13px;
  position: relative;
  top: 2px;
  margin-right: 7px;
}

@media (min-width: 320px) and (max-width: 640px) {
  .appWrapper.sidebar-xs #header .nav-right .nav-profile > a > span {
    display: inline-block;
  }
  .boxs.custom .boxs-header h1 {
    font-size: 19px;
  }
  .payment-detail-list .refund-info .refunds li .right-txt {
    width: 100%;
  }
  .tbl-2header thead tr:first-child {
    display: none;
  }

  .footable .btn-group {
    width: 80% !important;
  }
}

h1 .label {
  position:relative;
  top:-3px
}

datamap {
  padding-bottom: 100% !important;
}

#heatmapContainer {
  max-width:800px;
  max-height:600px;
  margin:auto;
}

.fooicon-sort, .fooicon-sort-desc, .fooicon-sort-asc {
  float: right;
}

.grecaptcha-badge {
  display:none;
}

.faq {
  padding-bottom: 20px;
}

.hint {
  display:block;
  font-size: 12px;
  font-weight: lighter;
  margin-bottom:10px;
}

.picker-color {
  width:16px;
  height:16px;
  border:1px solid black;
  display:inline-block;
  margin-top:4px;
  margin-right:4px;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-fflexbox;
  display:         flex;
}

.trafficLight {
  max-width: 50px;
}

.chat {
  position:fixed;
  z-index:100000000;
  bottom:0px;
  right:0;
  width:500px;
  padding-bottom:0;
  margin-bottom:0
}

.supportChat {
  color: green
}