.game {
  background-image: url('../imgs/games/unknown.png');
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 10px;
}

.game-csgo {
   width: 16px;
   height: 16px;
   background-image: url('../imgs/games/csgo.png');
 }

.game-cstrike {
  width: 16px;
  height: 16px;
  background-image: url('../imgs/games/cstrike.png');
}

.game-tf {
  width: 16px;
  height: 16px;
  background-image: url('../imgs/games/tf.png');
}

.game-dods {
  width: 16px;
  height: 16px;
  background-image: url('../imgs/games/dods.png');
}

.game-garrysmod {
  width: 16px;
  height: 16px;
  background-image: url('../imgs/games/garrysmod.png');
}
.game-hl {
   width: 16px;
   height: 16px;
   background-image: url('../imgs/games/hl.png');
}

.game-hl2dm {
  width: 16px;
  height: 16px;
  background-image: url('../imgs/games/hl2dm.png');
}

.game-left4dead {
  width: 16px;
  height: 16px;
  background-image: url('../imgs/games/l4d.png');
}

.game-left4dead2 {
  width: 16px;
  height: 16px;
  background-image: url('../imgs/games/left4dead2.png');
}


.game-minecraft {
  width: 16px;
  height: 16px;
  background-image: url('../imgs/games/minecraft.png');
}


.game-nmrih {
  width: 16px;
  height: 16px;
  background-image: url('../imgs/games/nmrih.png');
}