/*********************************/
/************* CARDS *************/
/*********************************/

.card-container {
  position: relative;

  .card {
    min-height: 110px;
    height: 100%;
    padding-bottom: 20px;
    position: relative;
    @include perspective(600px);
    width: 100%;
    cursor: pointer;
    z-index: 9;

    .front {
      height: 100%;
      position: relative;
      width: 100%;
      padding: 28px;
      top: 0;
      left: 0;
      z-index: 11;

      @include transform(rotateX(0deg) rotateY(0deg));

      @include transform-style(preserve-3d);

      @include backface-visibility(hidden);

      /* -- transition is the magic sauce for animation -- */
      @include transition(all .4s ease-in-out);
	  .col-xs-4{
		  text-align:center;}
    }

   

    .back {
      position: absolute;
      top: 0;
      border: 0;
      height: 100%;
      width: 100%;
      left: 0;
      z-index: 10;

      -webkit-transform: rotateY(-180deg);
         -moz-transform: rotateY(-179deg); /* setting to 180 causes an unnatural-looking half-flip */
              transform: rotateY(-179deg);

      @include transform-style(preserve-3d);

      @include backface-visibility(hidden);

      /* -- transition is the magic sauce for animation -- */
      @include transition(all .4s ease-in-out);

      >.row {
        height: 100%;
        margin: 0;

        >div {
          height: 100%;
          padding: 0;

          a {
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            padding: 30px 10px 10px;

            i {
              display: block;
              margin-bottom: 5px;
            }

            &:hover {
              text-decoration: none;
            }
          }

          &:first-of-type {
            a {
              background-color: $transparent-black-1;
            }
          }

          &:nth-of-type(2) {
            a {
              background-color: $transparent-black-05;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  .card-container .card .front {
    padding: 28px 10px;
  }
}
